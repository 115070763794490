<div class="sb-nav-fixed" [ngClass]="{'sb-sidenav-toggled':  isMenuVisible}" >
  <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark" *ngIf="auth.isLoggedIn()">
    <button *ngIf="auth.isLoggedIn()" class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle"
      (click)="toggleManuClass()"><i class="fa fa-bars"></i></button>
    <a class="navbar-brand" [routerLink]="auth.isLoggedIn()?'eventos':''">Plataforma Eventos</a>
  </nav>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav" *ngIf="auth.isLoggedIn()">
      <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
        <div class="sb-sidenav-menu">
          <div class="nav">
            <a class="nav-link" routerLink="eventos">
              <div class="sb-nav-link-icon"><i class="fa fa-calendar"></i></div>
              Eventos
            </a>
            <a class="nav-link" routerLink="login/senha">
              <div class="sb-nav-link-icon"><i class="fa fa-user"></i></div>
              Trocar Senha
            </a>

            <div class="nav-link" (click)="logout()">
              <div class="sb-nav-link-icon"><i class="fa fa-sign-out"></i></div>
              Sair
            </div>
          </div>
        </div>
        <div class="sb-sidenav-footer">
          <div class="small">Acessando como:</div>
          {{auth.getName()}}<div class="btn btn-link" (click)="logout()">

          </div>
        </div>
      </nav>
    </div>
    <div id="layoutSidenav_content">
      <main>
        <router-outlet></router-outlet>
      </main>
      <footer class="py-4 bg-light mt-auto" *ngIf="auth.isLoggedIn()">
        <div class="container-fluid">
          <div class="d-flex align-items-center justify-content-between small">
            <div class="text-muted">Copyright &copy; Especialtá Eventos {{ano}}</div>
            <div>Criado por Junior
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>
