import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent } from 'src/app/login/components/login'

const routes: Routes = [
  { path: 'inscricoes', loadChildren: () => import('./inscricoes/inscricoes.module').then(m => m.InscricoesModule) },
  { path: 'eventos', loadChildren: () => import('./eventos/eventos.module').then(m => m.EventosModule) },
  { path: 'dataprivacy', loadChildren: () => import('./dataprivacy/dataprivacy.module').then(m => m.DataPrivacyModule) },
  { path: 'login',component:LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
