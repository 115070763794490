// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  API_PATH: 'http://especialita.vps-uni5.net:7788/api/v1',
  SITE_URL:'http://especialita.vps-uni5.net:4200',
  listaStatusInscricao : [
    {class:'btn-success',name:'Pago'},
    {class:'btn-danger',name:'Aguardando Pagamento'},
    {class:'btn-primary',name:'Cortesia'},
    {class:'btn-primary',name:'Staff'},
    {class:'btn-secondary',name:'Palestrante'}]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
