<div class="login-page">
  <div class="login-box">
    <div class="login-logo">
      <img src="assets/logo mini.png" width="220px">
    </div>
    <!-- /['login']-logo -->
    <!-- form start -->
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="card">
        <div class="card-body login-card-body">
          <alert></alert>
          <div class="input-group mb-3">
            <input type="input" formControlName="login" class="form-control" placeholder="Login" maxlength="11"
              [ngClass]="{ 'is-invalid': submitted && f['login'].errors }" />
            <div *ngIf="submitted && f['login'].errors" class="invalid-feedback">
              <div *ngIf="f['login'].errors['required']">O campo Login é obrigatório</div>
            </div>
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-address-card"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" formControlName="password" class="form-control" placeholder="Senha" maxlength="100"
              [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
              <div *ngIf="f['password'].errors['required']">O campo Senha é obrigatório</div>
            </div>
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- /.col -->
            <div class="col-4">
              <button [disabled]="loading" type="submit" class="btn btn-primary btn-block">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Entrar
              </button>
            </div>
            <!-- /.col -->
          </div>

        </div>
        <!-- /['login']-card-body -->
      </div>
    </form>
  </div>
</div>
