import { Injectable } from '@angular/core';
import { SessionStorageService } from '../session-storage/session-storage.service';
import {IAuth} from 'src/app/login/LoginInterfaces'

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  constructor(
    private sessionStorage: SessionStorageService
  ) { }

  isLoggedIn() {
      return this.sessionStorage.getItem('token')!=null &&  this.sessionStorage.getItem('token')!='null';
  }

  isAdmin() {
    return this.sessionStorage.getItem('name') == 'Admin';
  }

  getName() {
    return this.sessionStorage.getItem('name');
  }

  updateName(name: string) {
    this.sessionStorage.setItem('name', name);
  }

  getEmail() {
    return this.sessionStorage.getItem('email');
  }

  logout() {
    this.sessionStorage.removeItem('token');
    this.sessionStorage.removeItem('refreshToken');
    this.sessionStorage.removeItem('name');
  }

  login(data: IAuth) {
    this.sessionStorage.setItemWithExpiration('name', data.name, data.expireIn);
    this.sessionStorage.setItemWithExpiration('token', data.token, data.expireIn);
    this.sessionStorage.setItemWithExpiration('refreshToken', data.refreshToken,parseInt(data.expireIn)*24);
  }

  getAuthorizationHeader() {
    return {Authorization: 'Bearer ' + window.sessionStorage.getItem('token')};
  }
}
