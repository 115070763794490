import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService } from 'src/app/components/alert/service/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LoginService } from 'src/app/login/service/login.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) { }

  form!: FormGroup;
  loading = false;
  submitted = false;

  async onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    try {
      let data = await this.loginService.login(this.form.controls['login'].value, this.form.controls['password'].value);
      this.authenticationService.login(data);

      this.loading = false;
      this.router.navigate(['/eventos']);

    }
    catch (e) {
      this.loading = false;

      this.alertService.clear();
      this.alertService.error('Usuário e senha incorretos', { keepAfterRouteChange: true });
    }

  }
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

}
