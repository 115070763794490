import { Component,OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication/authentication.service'
import { Router } from '@angular/router';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit{
  title = 'front';
  constructor(
    public auth:AuthenticationService,
    private session:SessionStorageService,
    private routerService: Router
  ) { }

  ano=(new Date()).getFullYear()
  public isMenuVisible: boolean = false;
  toggleManuClass() {
    this.isMenuVisible = !this.isMenuVisible;
    this.session.setItem('menuVisible',String(this.isMenuVisible))
  }
  logout(){
    this.auth.logout();
    this.routerService.navigate(['/login'])
  }


  ngOnInit() {
    this.isMenuVisible = this.session.getItem('menuVisible')=='true'
    let cc = window as any;
       cc.cookieconsent.initialise({
         palette: {
           popup: {
             background: "#eee"
           },
           button: {
             background: "#007bff",
             text: "#eee"
           }
         },
         theme: "classic",
         content: {
           message: "Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços. Ao utilizar nossos serviços, você está ciente dessa funcionalidade. Consulte nossa",
           dismiss: 'Entendi',
           allow: 'Aceitar',
           link: 'Política de Privacidade',
           href: "/dataprivacy"
         }
       });
  }
}
